import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Pricing.css';

const Pricing = () => {
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/subscriptions/plans`);
        setPlans(response.data);
      } catch (error) {
        console.error('Error fetching subscription plans:', error);
      }
    };

    fetchPlans();
  }, []);

  const handleSubscriptionSelect = (tier) => {
    navigate('/register', { state: { selectedTier: tier } });
  };

  const handleCancel = () => {
    navigate('/login');
  };

  return (
    <div className="pricing-container">
      <div className="pricing-content">
        <h1>Pricing</h1>
        <p>Get started by selecting the subscription that best fits your needs. Choose from the following tiers:</p>
        
        <div className="pricing-tiers">
          {plans.map((plan, index) => (
            <div key={index} className="pricing-tier">
              <h2>{plan.tier}</h2>
              <p className="price">{typeof plan.price === 'number' ? `$${plan.price} USD` : plan.price}</p>
              <p className="duration">{plan.duration}</p>
              <ul>
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex}>{feature}</li>
                ))}
              </ul>
              <button onClick={() => handleSubscriptionSelect(plan.tier)}>
                {plan.tier === 'Free' ? 'Select Free Trial' : 
                 plan.tier === 'Enterprise' ? 'Contact Sales' : 
                 `Select ${plan.tier} Plan`}
              </button>
            </div>
          ))}
        </div>
        <button className="cancel-button" onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default Pricing;