import React from 'react';
import "@radix-ui/themes/styles.css";
import Intercom from '@intercom/messenger-js-sdk';
import { Theme } from "@radix-ui/themes";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import HomeChat from './pages/HomeChat';
import Agents from './pages/Agents';
import CompanyInformation from './components/CompanyInformation';
import CreateAgentModal from './pages/CreateAgentModal';
import CreateAdminAgentModal from './admin/CreateAdminAgentModal';
import EditAgentPage from './pages/EditAgentPage';
import Knowledge from './pages/Knowledge';
import Integrations from './pages/Integrations';
import Analytics from './pages/Analytics';
import Roadmap from './pages/Roadmap';
import Tutorials from './pages/Tutorials';
import Documentation from './pages/Documentation';
import Community from './pages/Community';
import Login from './components/Login';
import Register from './components/Register';
import RegisterSSO from './components/RegisterSSO';
import Pricing from './components/Pricing';
import MyAccount from './pages/MyAccount';
import Team from './pages/Team';
import Feedback from './pages/Feedback'
import { AuthProvider, useAuth } from './context/AuthContext';
import './App.css';
import HelpPopup from './components/HelpPopup';
// import Settings from './pages/Settings'
// import HelpCenter from './pages/HelpCenter';
// import Prompts from './pages/Prompts';
// import Finetune from './pages/Finetune';
// import UnderConstruction from './components/UnderConstruction';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const SuperAdminProtectedRoute = ({ children }) => {
  const { isAuthenticated, isSuperAdmin } = useAuth();
  
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  
  if (!isSuperAdmin) {
    return <Navigate to="/home" />;
  }
  
  return children;
};

function AppContent() {
  const { isAuthenticated, loading } = useAuth();
  const showSidebar = isAuthenticated && !['/login', '/register', '/pricing'].includes(window.location.pathname);
  const showHelp = isAuthenticated && !['/login', '/register', '/pricing'].includes(window.location.pathname);

  return (
    <div className="app">
      {showSidebar && <Sidebar />}
      {showHelp && <HelpPopup />}
      <main className={showSidebar ? "main-content" : ""}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register-sso" element={<RegisterSSO />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/" element={<ProtectedRoute><HomeChat /></ProtectedRoute>} />
          <Route path="/company-info" element={<ProtectedRoute><CompanyInformation /></ProtectedRoute>} />
          <Route path="/home" element={<ProtectedRoute><HomeChat /></ProtectedRoute>} />
          <Route path="/home/chat/:chatId" element={<ProtectedRoute><HomeChat /></ProtectedRoute>} />
          <Route path="/home/agents/:chatId" element={<ProtectedRoute><HomeChat /></ProtectedRoute>} />
          <Route path="/agents" element={<ProtectedRoute><Agents /></ProtectedRoute>} />
          <Route path="/agents/create" element={<ProtectedRoute><CreateAgentModal /></ProtectedRoute>} />
          <Route path="/agents/create/admin" element={<SuperAdminProtectedRoute><CreateAdminAgentModal /></SuperAdminProtectedRoute>} />
          <Route path="/agents/edit/:agentId" element={<ProtectedRoute><EditAgentPage /></ProtectedRoute>} />
          <Route path="/knowledge" element={<ProtectedRoute><Knowledge /></ProtectedRoute>} />
          <Route path="/integrations" element={<ProtectedRoute><Integrations /></ProtectedRoute>} />
          <Route path="/analytics" element={<ProtectedRoute><Analytics /></ProtectedRoute>} />
          <Route path="/tutorials" element={<ProtectedRoute><Tutorials /></ProtectedRoute>} />
          <Route path="/documentation" element={<ProtectedRoute><Documentation /></ProtectedRoute>} />
          <Route path="/feedback" element={<ProtectedRoute><Feedback /></ProtectedRoute>} />
          <Route path="/community" element={<ProtectedRoute><Community /></ProtectedRoute>} />
          <Route path="/roadmap" element={<ProtectedRoute><Roadmap /></ProtectedRoute>} />
          <Route path="/my-account" element={<ProtectedRoute><MyAccount /></ProtectedRoute>} />
          <Route path="/team" element={<ProtectedRoute><Team /></ProtectedRoute>} />
          {/*<Route path="/finetune" element={<ProtectedRoute><Finetune /></ProtectedRoute>} />
          <Route path="/help-center" element={<ProtectedRoute><HelpCenter /></ProtectedRoute>} />
          <Route path="/prompts" element={<ProtectedRoute><Prompts /></ProtectedRoute>} />
          <Route path="/maintenance" element={<ProtectedRoute><UnderConstruction /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />*/}
        </Routes>
      </main>
      
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Theme>
          <AppContent />
        </Theme>
      </Router>
    </AuthProvider>
  );
}

export default App;