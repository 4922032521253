import React, { useRef, useState } from 'react';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell
} from 'recharts';
import './GraphRenderer.css'

// Professional color palette
const COLORS = {
  primary: '#2563eb',    // Blue
  secondary: '#7c3aed',  // Purple
  success: '#16a34a',    // Green
  warning: '#d97706',    // Orange
  error: '#dc2626',      // Red
  accent1: '#0891b2',    // Cyan
  accent2: '#db2777',    // Pink
  gray: '#6b7280',       // Gray
};

// Array of colors for multiple series
const COLOR_PALETTE = Object.values(COLORS);

// Context Menu Component
const ContextMenu = ({ x, y, onClose, onSave }) => {
  return (
    <div 
      className="graph-context-menu"
      style={{ 
        top: y,
        left: x,
      }}
    >
      <button onClick={onSave}>Save as Image</button>
      <button onClick={onClose}>Cancel</button>
    </div>
  );
};//

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="graph-tooltip">
        <p className="tooltip-label">{label}</p>
        {payload.map((entry, index) => (
          <p 
            key={index} 
            className="tooltip-value"
            style={{ color: entry.color }}
          >
            {`${entry.name}: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const GraphRenderer = ({ code }) => {
  const containerRef = useRef(null);
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (e) => {
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const saveChartAsImage = async () => {
    try {
      const svgElement = containerRef.current?.getElementsByTagName('svg')[0];
      if (!svgElement) return;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      
      const svgRect = svgElement.getBoundingClientRect();
      const margin = 20;
      canvas.width = svgRect.width + (margin * 2);
      canvas.height = svgRect.height + (margin * 2);

      // Set white background explicitly
      ctx.fillStyle = '#ffffff';  // or '#FFFFFF' or 'white'
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      const svgData = new XMLSerializer().serializeToString(svgElement);
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);

      const img = new Image();
      img.src = svgUrl;
      
      await new Promise((resolve, reject) => {
        img.onload = () => {
          ctx.drawImage(img, margin, margin);
          resolve();
        };
        img.onerror = reject;
      });

      const link = document.createElement('a');
      link.download = 'chart.png';
      link.href = canvas.toDataURL('image/png');
      link.click();

      URL.revokeObjectURL(svgUrl);
      handleCloseContextMenu();

    } catch (error) {
      console.error('Error saving chart:', error);
    }
  };

  const getGraphConfig = () => {
    try {
      const dataMatch = code.match(/const\s+data\s*=\s*(\[[\s\S]*?\]);/);
      const configMatch = code.match(/const\s+config\s*=\s*({[\s\S]*?});/);
      
      if (!dataMatch || !configMatch) {
        throw new Error('Invalid graph code format');
      }

      const data = eval(dataMatch[1]);
      const config = eval(`(${configMatch[1]})`);

      return { data, config };
    } catch (error) {
      console.error('Error parsing graph code:', error);
      return null;
    }
  };

  const renderGraph = () => {
    const graphConfig = getGraphConfig();
    if (!graphConfig) return <div className="graph-error">Error rendering graph</div>;

    const { data, config } = graphConfig;

    // Common axis styling
    const axisStyle = {
      fontSize: '12px',
      fontFamily: 'Inter, system-ui, sans-serif',
      tickLine: false,
    };

    // Common grid styling
    const gridStyle = {
      strokeDasharray: "3 3",
      stroke: "#e5e7eb",
      opacity: 0.7,
    };

    switch (config.type) {
      case 'line':
        return (
          <ResponsiveContainer width="98.5%" height={400}>
            <LineChart data={data} >
              <CartesianGrid {...gridStyle} />
              <XAxis 
                dataKey={config.xAxis} 
                {...axisStyle}
                dy={10}
              />
              <YAxis {...axisStyle} dx={-10} />
              <Tooltip 
                content={<CustomTooltip />}
                cursor={{ stroke: '#6b7280', strokeWidth: 1 }}
              />
              <Legend 
                wrapperStyle={{ paddingTop: '20px' }}
                verticalAlign="bottom"
              />
              {config.lines.map((line, index) => (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={line.dataKey}
                  stroke={line.color || COLOR_PALETTE[index % COLOR_PALETTE.length]}
                  strokeWidth={2}
                  dot={{ 
                    r: 4,
                    strokeWidth: 2,
                    fill: '#ffffff'
                  }}
                  activeDot={{ 
                    r: 6,
                    strokeWidth: 0,
                    fill: line.color || COLOR_PALETTE[index % COLOR_PALETTE.length]
                  }}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        );

      case 'bar':
        return (
          <ResponsiveContainer width="98.5%" height={400}>
            <BarChart data={data}>
              <CartesianGrid {...gridStyle} />
              <XAxis 
                dataKey={config.xAxis} 
                {...axisStyle}
                dy={10}
              />
              <YAxis {...axisStyle} dx={-10} />
              <Tooltip content={<CustomTooltip />} />
              <Legend 
                wrapperStyle={{ paddingTop: '20px' }}
                verticalAlign="bottom"
              />
              {config.bars.map((bar, index) => (
                <Bar
                  key={index}
                  dataKey={bar.dataKey}
                  fill={bar.color || COLOR_PALETTE[index % COLOR_PALETTE.length]}
                  radius={[4, 4, 0, 0]}
                  maxBarSize={50}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        );

      case 'pie':
        return (
          <ResponsiveContainer width="98.5%" height={400}>
            <PieChart>
              <Pie
                data={data}
                dataKey={config.dataKey}
                nameKey={config.nameKey}
                cx="50%"
                cy="50%"
                outerRadius={150}
                label={({ name, value }) => `${name}: ${value}`}
                labelLine={{ stroke: '#6b7280', strokeWidth: 1 }}
              >
                {data.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`}
                    fill={COLOR_PALETTE[index % COLOR_PALETTE.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              <Legend 
                wrapperStyle={{ paddingTop: '20px' }}
                verticalAlign="bottom"
              />
            </PieChart>
          </ResponsiveContainer>
        );

      case 'area':
        return (
          <ResponsiveContainer width="98.5%" height={400}>
            <AreaChart data={data}>
              <CartesianGrid {...gridStyle} />
              <XAxis 
                dataKey={config.xAxis} 
                {...axisStyle}
                dy={10}
              />
              <YAxis {...axisStyle} dx={-10} />
              <Tooltip content={<CustomTooltip />} />
              <Legend 
                wrapperStyle={{ paddingTop: '20px' }}
                verticalAlign="bottom"
              />
              {config.areas.map((area, index) => (
                <Area
                  key={index}
                  type="monotone"
                  dataKey={area.dataKey}
                  fill={area.color || COLOR_PALETTE[index % COLOR_PALETTE.length]}
                  stroke={area.stroke || COLOR_PALETTE[index % COLOR_PALETTE.length]}
                  fillOpacity={0.2}
                  strokeWidth={2}
                />
              ))}
            </AreaChart>
          </ResponsiveContainer>
        );

      default:
        return <div className="graph-error">Unsupported graph type</div>;
    }
  };

  return (
    <div 
      ref={containerRef} 
      className="graph-container"
      onContextMenu={handleContextMenu}
      onClick={handleCloseContextMenu}
    >
      {renderGraph()}
      {contextMenu && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={handleCloseContextMenu}
          onSave={saveChartAsImage}
        />
      )}
    </div>
  );
};

export default GraphRenderer;