import React from 'react';

const Community = () => {
  return (
    <div className="page-container">
      <h1>Community</h1>
      <p>Connect with other users and share your experiences in our community forum.</p>
    </div>
  );
};

export default Community;