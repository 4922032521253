import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Eye, EyeOff, Check } from 'lucide-react';
import axios from 'axios';
import './Login.css';
import Swal from 'sweetalert2'

const RegisterSSO = () => {
  const [step, setStep] = useState(2); // Start from step 2
  const [credentials, setCredentials] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  // , subscriptionTier: ''
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  // useEffect(() => {
  //   if (location.state?.selectedTier) {
  //     setCredentials(prev => ({ ...prev, subscriptionTier: location.state.selectedTier }));
  //   } else {
  //     navigate('/pricing');
  //   }
  // }, [location.state, navigate]);

  useEffect(() => {
    if (location.state?.googleUser) {
      setCredentials(prevState => ({
        ...prevState,
        firstName: location.state.googleUser.firstName,
        lastName: location.state.googleUser.lastName,
        email: location.state.googleUser.email,
        password: location.state.googleUser.password,
      }));
    } else {
      // If there's no Google user data, redirect to regular register
      navigate('/register');
    }
  }, [location.state, navigate]);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError('');
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth/register`, credentials);
  //     if (response.data.companyId) {
  //       localStorage.setItem('companyId', response.data.companyId);
  //     }
  //     // Attempt to log in the user after successful registration
  //     const loginSuccess = await login({ email: credentials.email, password: credentials.password }); // No password for SSO users
  //     if (loginSuccess) {
  //       navigate('/home');
  //       Swal.fire({
  //         position: "top-end",
  //         icon: "success",
  //         title: "Login successful",
  //         showConfirmButton: false,
  //         timer: 1000
  //       });
  //     } else {
  //       setError('Registration successful, but unable to log in. Please try logging in manually.');
  //       Swal.fire({
  //         icon: "error",
  //         title: "Login Error.",
  //         text: "Registration successful, but unable to log in. Please try logging in manually.",
  //         // footer: '<a href="#">Why do I have this issue?</a>'
  //       });
  //     }
  //   } catch (error) {
  //     setError(error.response?.data?.message || 'An error occurred during registration');
  //   }
  // };

  const handleLogin = async () => {
    const success = await login({ email: credentials.email, password: credentials.password });
    if (success) {
      navigate('/home');
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Login successful",
        showConfirmButton: false,
        timer: 1000
      });
    } else {
      setError('Unable to log in. Please try logging in manually.');
      Swal.fire({
        icon: "error",
        title: "Login Error.",
        text: "Registration successful, but unable to log in. Please try logging in manually.",
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }
  };

  return (
    <div className="login-container">
      <div className="final-step">
        <h2>All set: AIs know you now</h2>
        <div className="checkmark">✓</div>
        <button onClick={handleLogin} className="primary-button">
          Go to Calk
        </button>
      </div>
    </div>
  );
};
export default RegisterSSO;