import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import Swal from 'sweetalert2'

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await verifyToken(token);
          if (response.isValid) {
            setIsAuthenticated(true);
            setUserRole(response.user.role);
            setUserFirstName(response.user.firstName);
            setUserLastName(response.user.lastName);
            setUserEmail(response.user.email);
            setCompanyId(response.user.companyId);
            setIsSuperAdmin(response.user.isSuperAdmin);
            // await checkSubscriptionStatus(response.user.companyId);
          } else {
            clearLocalStorage();
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Oops...",
            text: "Error verifying connection. Refresh the page.",
            showConfirmButton: false,
            timer: 3000
            // footer: '<a href="#">Why do I have this issue?</a>'
          });
        }
      }
      setLoading(false);
    };

    initializeAuth();
  }, []);

  const checkSubscriptionStatus = async (companyId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/team/subscription`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setSubscriptionStatus(response.data.status);
    } catch (error) {
      console.error('Error checking subscription status:', error);
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userFirstName');
    localStorage.removeItem('userLastName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('companyId');
    localStorage.removeItem('isSuperAdmin');
  };

  const verifyToken = async (token) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth/verify-token`, { token });
      return response.data;
    } catch (error) {
      console.error('Error verifying token:', error);
      // Swal.fire({
      //   position: "top-end",
      //   icon: "error",
      //   title: "Oops...",
      //   text: "Error verifying connection. Refresh the page.",
      //   showConfirmButton: false,
      //   timer: 3000
      //   // footer: '<a href="#">Why do I have this issue?</a>'
      // });
      return { isValid: false };
    }
  };

  const login = async (credentials) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth/login`, credentials);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userRole', response.data.user.role);
      localStorage.setItem('userFirstName', response.data.user.firstName);
      localStorage.setItem('userLastName', response.data.user.lastName);
      localStorage.setItem('userEmail', response.data.user.email);
      localStorage.setItem('companyId', response.data.user.companyId);
      localStorage.setItem('isSuperAdmin', response.data.user.isSuperAdmin);
      setIsAuthenticated(true);
      setUserRole(response.data.user.role);
      setUserFirstName(response.data.user.firstName);
      setUserLastName(response.data.user.lastName);
      setUserEmail(response.data.user.email);
      setCompanyId(response.data.user.companyId);
      setIsSuperAdmin(response.data.user.isSuperAdmin);
      // await checkSubscriptionStatus(response.data.user.companyId);
      return { success: true };
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Login successful",
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Login error:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: "Login error.",
        showConfirmButton: false,
        timer: 3000
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
      return { 
        success: false, 
        message: error.response?.data?.message || 'An error occurred during login' 
      };
    }
  };

  // const googleLogin = async (credential) => {
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth/google-login`, { credential });
  //     localStorage.setItem('token', response.data.token);
  //     localStorage.setItem('userRole', response.data.user.role);
  //     localStorage.setItem('userFirstName', response.data.user.firstName);
  //     localStorage.setItem('userLastName', response.data.user.lastName);
  //     localStorage.setItem('userEmail', response.data.user.email);
  //     localStorage.setItem('companyId', response.data.user.companyId);
  //     setIsAuthenticated(true);
  //     setUserRole(response.data.user.role);
  //     setUserFirstName(response.data.user.firstName);
  //     setUserLastName(response.data.user.lastName);
  //     setUserEmail(response.data.user.email);
  //     setCompanyId(response.data.user.companyId);
  //     return { success: true };
  //   } catch (error) {
  //     console.error('Google login error:', error);
  //     return { 
  //       success: false, 
  //       message: error.response?.data?.message || 'An error occurred during Google login' 
  //     };
  //   }
  // };

  const googleLogin = async (credential) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth/google-login`, { credential });
      
      if (response.data.isNewUser) {
        // For new users, we don't set authentication state yet
        return { 
          success: true, 
          isNewUser: true,
          user: response.data.user
        };
      } else {
        // For existing users, we set authentication state
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userRole', response.data.user.role);
        localStorage.setItem('userFirstName', response.data.user.firstName);
        localStorage.setItem('userLastName', response.data.user.lastName);
        localStorage.setItem('userEmail', response.data.user.email);
        localStorage.setItem('companyId', response.data.user.companyId);
        localStorage.setItem('isSuperAdmin', response.data.user.isSuperAdmin);
        setIsAuthenticated(true);
        setUserRole(response.data.user.role);
        setUserFirstName(response.data.user.firstName);
        setUserLastName(response.data.user.lastName);
        setUserEmail(response.data.user.email);
        setCompanyId(response.data.user.companyId);
        setIsSuperAdmin(response.data.user.isSuperAdmin);
        return { success: true, isNewUser: false };
      }
    } catch (error) {
      console.error('Google login error:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: "Login error.",
        showConfirmButton: false,
        timer: 3000
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
      return { 
        success: false, 
        message: error.response?.data?.message || 'An error occurred during Google login' 
      };
    }
  };

  const register = async (userData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth/register`, userData);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userRole', 'Administrator');
      localStorage.setItem('userFirstName', userData.firstName);
      localStorage.setItem('userLastName', userData.lastName);
      localStorage.setItem('userEmail', userData.email);
      localStorage.setItem('companyId', response.data.companyId);
      setIsAuthenticated(true);
      setUserRole('Administrator');
      setUserFirstName(userData.firstName);
      setUserLastName(userData.lastName);
      setUserEmail(userData.email);
      setCompanyId(response.data.companyId);
      return true;
    } catch (error) {
      console.error('Registration error:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: "Registration error.",
        showConfirmButton: false,
        timer: 3000
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
      return false;
    }
  };

  const logout = () => {
    clearLocalStorage();
    setIsAuthenticated(false);
    setUserRole(null);
    setUserFirstName(null);
    setUserLastName(null);
    setUserEmail(null);
    setCompanyId(null);
    setIsSuperAdmin(false);
  };

  const updateProfile = async (profileData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth/update-profile`, 
        profileData,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      
      if (response.data && response.data.user) {
        // Update local storage and state with new user data
        localStorage.setItem('userFirstName', response.data.user.firstName);
        localStorage.setItem('userLastName', response.data.user.lastName);
        localStorage.setItem('userEmail', response.data.user.email);
        setUserFirstName(response.data.user.firstName);
        setUserLastName(response.data.user.lastName);
        setUserEmail(response.data.user.email);
        
        return { success: true, user: response.data.user };
      } else {
        return { success: false, message: 'Profile update failed' };
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      return { success: false, message: error.response?.data?.message || 'An error occurred during profile update' };
    }
  };

  const updatePassword = async (currentPassword, newPassword) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth/change-password`, 
        { currentPassword, newPassword },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      return { success: true, message: response.data.message };
    } catch (error) {
      console.error('Error updating password:', error);
      return { success: false, message: error.response?.data?.message || 'Error updating password' };
    }
  };

  if (loading) {
    return (
      <div className="loading-page">
        <div className="loading-container">
          <div className="loading-page-logo">
            <img src="/calk-name.png" alt="Calk" />
          </div>
          <div className="loading-bar">
            <div className="loading-bar-shimmer">
              <div className="loading-bar-gradient" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      userRole, 
      userFirstName,
      userLastName,
      userEmail,
      companyId,
      isSuperAdmin,
      login, 
      register,
      logout,
      updateProfile,
      updatePassword,
      googleLogin
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

      // subscriptionStatus,