import React from 'react';

const Tutorials = () => {
  return (
    <div className="page-container">
      <h1>Tutorials</h1>
      <p>Access helpful tutorials and guides to get the most out of our platform.</p>
    </div>
  );
};

export default Tutorials;