import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Eye, EyeOff, Check } from 'lucide-react';
import axios from 'axios';
import './Login.css';
import Swal from 'sweetalert2'

const Register = () => {
  const [step, setStep] = useState(1);
  const [credentials, setCredentials] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  // , subscriptionTier: ''
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  // useEffect(() => {
  //   if (location.state?.selectedTier) {
  //     setCredentials(prev => ({ ...prev, subscriptionTier: location.state.selectedTier }));
  //   } else {
  //     navigate('/pricing');
  //   }
  // }, [location.state, navigate]);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth/register`, credentials);
      if (response.data.companyId) {
        localStorage.setItem('companyId', response.data.companyId);
      }
      handleLogin();
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred during registration');
    }
  };

  const handleLogin = async () => {
    const success = await login({ email: credentials.email, password: credentials.password });
    if (success) {
      navigate('/home');
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Login successful",
        showConfirmButton: false,
        timer: 1000
      });
    } else {
      setError('Unable to log in. Please try logging in manually.');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <img src="/calk-name.png" alt="Calk Logo" className="logo" />

        <div className="input-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={credentials.firstName}
            onChange={handleChange}
            placeholder="Your First Name"
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={credentials.lastName}
            onChange={handleChange}
            placeholder="Your Last Name"
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="email">Work Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={credentials.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <div className="password-input">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              placeholder="Password"
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="password-toggle"
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
        </div>
        <button type="submit" className="submit-button">Create account</button>
        <p className="form-link">
          Already have an account? <Link to="/login">Log in</Link>
        </p>

      </form>
    </div>
  );
};

export default Register;

        // <input
        //   type="hidden"
        //   name="subscriptionTier"
        //   value={credentials.subscriptionTier}
        // />

        // <p className="form-link">
        //   <Link to="/pricing">Back to Pricing</Link>
        // </p>