import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import * as Label from '@radix-ui/react-label';
import * as Dialog from '@radix-ui/react-dialog';
import { Building2, Target, Rocket, ChevronRight, ChevronLeft, Check } from 'lucide-react';
import axios from 'axios';
import './CompanyInformation.css';

const CompanyInformation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userEmail } = useAuth();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [companyInfo, setCompanyInfo] = useState({
    companyName: '',
    companyIndustry: '',
    companySize: '',
    businessModel: '',
    productDescription: '',
    targetMarket: '',
    businessChallenge: '',
    shortTermGoal: '',
    technologyLevel: '',
    geographicScope: '',
    growthStage: ''
  });

  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  const fetchCompanyDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/team/company-details`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      setCompanyInfo(response.data);
    } catch (error) {
      setError('Failed to fetch company information');
      console.error('Error fetching company information:', error);
    }
  };

  const handleChange = (e) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${process.env.REACT_APP_BACKEND}/api/team/company-information`,
        companyInfo,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
      );
      setSuccess('Company information updated successfully');
      setCurrentStep(4); // Move to completion step
      setTimeout(()=>{

          navigate('/home');
          window.location.reload(false);
      }, 1000);
    } catch (error) {
      setError('Failed to update company information');
      console.error('Error updating company information:', error);
    }
  };

  const nextStep = () => {
    setCurrentStep(prev => Math.min(prev + 1, 4));
  };

  const prevStep = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
  };

  const renderProgressBar = () => {
    return (
      <div className="progress-bar">
        {[1, 2, 3, 4].map((step) => (
          <div key={step} className={`progress-step ${step <= currentStep ? 'active' : ''}`}>
            {step < currentStep && <Check className="check-icon" />}
            {step === currentStep && <div className="step-number">{step}</div>}
          </div>
        ))}
      </div>
    );
  };

  const renderBasicInformation = () => (
    <div className="company-form-section">
      <div className="company-section-header">
        <Building2 className="company-section-icon" />
        <h3>Basic Incompany-formation</h3>
      </div>
      <div className="company-form-grid">
        <div className="company-form-group">
          <Label.Root className="company-form-label">Company Name</Label.Root>
          <input
            type="text"
            name="companyName"
            className="company-form-input"
            value={companyInfo.companyName}
            onChange={handleChange}
            placeholder="Your company name"
          />
        </div>

        <div className="company-form-group">
          <Label.Root className="company-form-label">Industry</Label.Root>
          <select
            name="companyIndustry"
            className="company-form-input"
            value={companyInfo.companyIndustry}
            onChange={handleChange}
          >
            <option value="">Select an industry</option>
            <option value="Technology & Software">Technology & Software</option>
            <option value="E-commerce">E-commerce</option>
            <option value="Digital Marketing">Digital Marketing</option>
            <option value="Financial Services">Financial Services</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Education">Education</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Retail">Retail</option>
          </select>
        </div>

        <div className="company-form-group">
          <Label.Root className="company-form-label">Company Size</Label.Root>
          <select
            name="companySize"
            className="company-form-input"
            value={companyInfo.companySize}
            onChange={handleChange}
          >
            <option value="">Select company size</option>
            <option value="1-5">1-5 employees</option>
            <option value="6-20">6-20 employees</option>
            <option value="21-50">21-50 employees</option>
            <option value="51-200">51-200 employees</option>
            <option value="201+">201+ employees</option>
          </select>
        </div>

        <div className="company-form-group">
          <Label.Root className="company-form-label">Business Model</Label.Root>
          <select
            name="businessModel"
            className="company-form-input"
            value={companyInfo.businessModel}
            onChange={handleChange}
          >
            <option value="">Select business model</option>
            <option value="B2B">B2B</option>
            <option value="B2C">B2C</option>
            <option value="B2B2C">B2B2C</option>
          </select>
        </div>
      </div>
    </div>
  );

  const renderMarketProduct = () => (
    <div className="company-form-section">
      <div className="company-section-header">
        <Target className="company-section-icon" />
        <h3>Market & Product</h3>
      </div>
      <div className="company-form-grid">
        <div className="company-form-group full-width">
          <Label.Root className="company-form-label">Product Description</Label.Root>
          <textarea
            name="productDescription"
            className="company-form-input"
            value={companyInfo.productDescription}
            onChange={handleChange}
            placeholder="Describe your product or service"
            rows={3}
          />
        </div>

        <div className="company-form-group">
          <Label.Root className="company-form-label">Target Market</Label.Root>
          <input
            type="text"
            name="targetMarket"
            className="company-form-input"
            value={companyInfo.targetMarket}
            onChange={handleChange}
            placeholder="Your target market"
          />
        </div>

        <div className="company-form-group">
          <Label.Root className="company-form-label">Geographic Scope</Label.Root>
          <select
            name="geographicScope"
            className="company-form-input"
            value={companyInfo.geographicScope}
            onChange={handleChange}
          >
            <option value="">Select geographic scope</option>
            <option value="Local">Local</option>
            <option value="Regional">Regional</option>
            <option value="National">National</option>
            <option value="International">International</option>
          </select>
        </div>
      </div>
    </div>
  );

  const renderGrowthChallenges = () => (
    <div className="company-form-section">
      <div className="company-section-header">
        <Rocket className="company-section-icon" />
        <h3>Growth & Challenges</h3>
      </div>
      <div className="company-form-grid">
        <div className="company-form-group">
          <Label.Root className="company-form-label">Business Challenge</Label.Root>
          <select
            name="businessChallenge"
            className="company-form-input"
            value={companyInfo.businessChallenge}
            onChange={handleChange}
          >
            <option value="">Select main challenge</option>
            <option value="Market Growth">Market Growth</option>
            <option value="Customer Acquisition">Customer Acquisition</option>
            <option value="Product Development">Product Development</option>
            <option value="Operations">Operations</option>
            <option value="Funding">Funding</option>
          </select>
        </div>

        <div className="company-form-group">
          <Label.Root className="company-form-label">Short Term Goal</Label.Root>
          <select
            name="shortTermGoal"
            className="company-form-input"
            value={companyInfo.shortTermGoal}
            onChange={handleChange}
          >
            <option value="">Select short term goal</option>
            <option value="Increase Revenue">Increase Revenue</option>
            <option value="Expand Market">Expand Market</option>
            <option value="Improve Product">Improve Product</option>
            <option value="Optimize Operations">Optimize Operations</option>
            <option value="Raise Capital">Raise Capital</option>
          </select>
        </div>

        <div className="company-form-group">
          <Label.Root className="company-form-label">Technology Level</Label.Root>
          <select
            name="technologyLevel"
            className="company-form-input"
            value={companyInfo.technologyLevel}
            onChange={handleChange}
          >
            <option value="">Select technology level</option>
            <option value="Basic">Basic</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Advanced">Advanced</option>
          </select>
        </div>

        <div className="company-form-group">
          <Label.Root className="company-form-label">Growth Stage</Label.Root>
          <select
            name="growthStage"
            className="company-form-input"
            value={companyInfo.growthStage}
            onChange={handleChange}
          >
            <option value="">Select growth stage</option>
            <option value="Pre-seed">Pre-seed</option>
            <option value="Seed">Seed</option>
            <option value="Growth">Growth</option>
            <option value="Mature">Mature</option>
          </select>
        </div>
      </div>
    </div>
  );

  const renderCompletion = () => (
    <div className="completion-step">
      <div className="completion-icon">
        <Check size={40} />
      </div>
      <h2>Company Profile Complete!</h2>
      <p>AI now has context about your business</p>
    </div>
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return renderBasicInformation();
      case 2:
        return renderMarketProduct();
      case 3:
        return renderGrowthChallenges();
      case 4:
        return renderCompletion();
      default:
        return null;
    }
  };

  return (
    <div className="company-container">
      <div className="company-content-panel">


        {/*{renderProgressBar()}*/}

        <company-form onSubmit={handleSubmit} className="company-form">
          {renderStepContent()}

          <div className="company-form-navigation">
            {currentStep > 1 && currentStep < 4 && (
              <button type="button" onClick={prevStep} className="nav-button prev">
                <ChevronLeft />
                Back
              </button>
            )}
            
            {currentStep < 3 && (
              <button type="button" onClick={nextStep} className="nav-button next">
                Next
                <ChevronRight />
              </button>
            )}
            
            {currentStep === 3 && (
              <button type="submit" className="nav-button submit" onClick={handleSubmit}>
                Complete Profile
              </button>
            )}
          </div>
        </company-form>
      </div>

      {error && <div className="company-error-message">{error}</div>}
      {success && <div className="company-success-message">{success}</div>}
    </div>
  );
};

export default CompanyInformation;