import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import * as Tabs from '@radix-ui/react-tabs';
import * as Label from '@radix-ui/react-label';
import * as Dialog from '@radix-ui/react-dialog';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Lock, Users, Download, Trash2 } from 'lucide-react';
import './MyAccount.css';
import Swal from 'sweetalert2'

const AccountContent = ({ profile, handleProfileChange, handleProfileUpdate, setShowPasswordModal }) => (
  <>
    <div className="profile-section">
      <div className="avatar-section">
        <div>
          <div className="avatar-container">
            {profile.firstName?.[0]}{profile.lastName?.[0]}
          </div>
        </div>

        <form onSubmit={handleProfileUpdate} className="form-container">
          <div className="form-group">
            <Label.Root className="form-label">Last Name</Label.Root>
            <input
              type="text"
              name="lastName"
              className="form-input"
              value={profile.lastName}
              onChange={handleProfileChange}
              placeholder="Last Name"
              required
            />
          </div>
          <div className="form-group">
            <Label.Root className="form-label">First Name</Label.Root>
            <input
              type="text"
              name="firstName"
              className="form-input"
              value={profile.firstName}
              onChange={handleProfileChange}
              placeholder="First Name"
              required
            />
          </div>
          <div className="form-group">
            <Label.Root className="form-label">Email Address</Label.Root>
            <input
              type="email"
              name="email"
              className="form-input"
              value={profile.email}
              onChange={handleProfileChange}
              required
            />
          </div>
          <div className="form-footer">
            <button type="submit" className="update-button">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>

    <div className="security-section">
      <h3 className="security-title">Security</h3>
      
      <div className="security-card">
        <div className="security-content">
          <Lock size={20} />
          <div>
            <div className="security-text">Change your password</div>
            <div className="security-description">Use a strong password to login to your Calk account.</div>
          </div>
        </div>
        <button className="button" onClick={() => setShowPasswordModal(true)}>
          Change Password
        </button>
      </div>

      <div className="security-card">
        <div className="security-content">
          <Users size={20} />
          <div>
            <div className="security-text">Two-factor authentication</div>
            <div className="security-description">Add additional verification to your account.</div>
          </div>
        </div>
        <button className="button soon">Coming Soon</button>
      </div>
    </div>
  </>
);

const MyAccount = () => {
  const { userFirstName, userLastName, userEmail, updateProfile, updatePassword } = useAuth();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [profile, setProfile] = useState({
    firstName: userFirstName || '',
    lastName: userLastName || '',
    email: userEmail || '',
  });
  const [passwords, setPasswords] = useState({
    current: '',
    new: '',
    confirm: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess('');
      }, 3000);

      // Cleanup function to clear timeout if component unmounts
      return () => clearTimeout(timer);
    }
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 3000);

      // Cleanup function to clear timeout if component unmounts
      return () => clearTimeout(timer);
    }
  }, [success, error]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (passwords.new !== passwords.confirm) {
      setError("Passwords don't match");
      return;
    }
    try {
      const result = await updatePassword(passwords.current, passwords.new);
      if (result.success) {
        // setSuccess('Password updated successfully');
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Password updated successfully",
          showConfirmButton: false,
          timer: 1000
        });
        setPasswords({ current: '', new: '', confirm: '' });
        setShowPasswordModal(false);
      } else {
        setError(result.message);
      }
    } catch (error) {
      // setError('Failed to change password');
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: "Failed to change password!",
        showConfirmButton: false,
        timer: 3000
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }
  };


  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile(prev => ({
      ...prev,
      [name]: value    // Use the destructured name instead of e.target.name
    }));
  };



  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const result = await updateProfile(profile);
      // console.log('Profile update result:', result); 

      if (result && !result.error) {
        // setSuccess('Profile updated successfully');
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Profile updated successfully",
          showConfirmButton: false,
          timer: 1000
        });
        // Update the local state to reflect the changes
        setProfile(prevProfile => ({
          ...prevProfile,
          firstName: result.user?.firstName || prevProfile.firstName,
          lastName: result.user?.lastName || prevProfile.lastName,
          email: result.user?.email || prevProfile.email
        }));
      } else {
        // setError(result.message || 'Failed to update profile');
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Oops...",
          text: "Failed to update profile!",
          showConfirmButton: false,
          timer: 3000
          // footer: '<a href="#">Why do I have this issue?</a>'
        });
      }
    } catch (error) {
      console.error('Profile update error:', error); // Add this line for debugging
      setError('An unexpected error occurred while updating the profile');
    }
  };

  const handleDeleteAccount = async () => {

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/delete-account`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        // setSuccess('Account deleted successfully');
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Account deleted successfully!",
          showConfirmButton: false,
          timer: 1000
        });
        // Optional: log the user out after deletion
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
      } else {
        const data = await response.json();
        // setError(data.message || 'Failed to delete account');
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Oops...",
          text: "Failed to delete account!",
          showConfirmButton: false,
          timer: 3000
          // footer: '<a href="#">Why do I have this issue?</a>'
        });
      }
    } catch (error) {
      setError('Failed to delete account');
    } finally {

    }
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/download-data`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to download data');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user-data.json';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();

    } catch (error) {
      console.error('Error downloading data:', error);
      // setError('Failed to download data');
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: "Failed to download data!",
        showConfirmButton: false,
        timer: 3000
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const getAvatarColor = (index) => `avatar-${index % 8}`;

  const getInitials = (firstName, lastName) => {
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
  };

  // const AccountContent = () => (
  //   <>
  //     <div className="profile-section">
  //       <div className="avatar-section">
  //         <div>
  //           <div className="avatar-container">
  //             {profile.firstName?.[0]}{profile.lastName?.[0]}
  //           </div>
  //         </div>

  //         <form onSubmit={handleProfileUpdate} className="form-container">
  //           <div className="form-group">
  //             <Label.Root className="form-label">Last Name</Label.Root>
  //             <input
  //               type="text"
  //               name="lastName"
  //               className="form-input"
  //               value={profile.lastName}
  //               onChange={handleProfileChange}
  //               placeholder="Last Name"
  //               required
  //             />
  //           </div>
  //           <div className="form-group">
  //             <Label.Root className="form-label">First Name</Label.Root>
  //             <input
  //               type="text"
  //               name="firstName"
  //               className="form-input"
  //               value={profile.firstName}
  //               onChange={handleProfileChange}
  //               placeholder="First Name"
  //               required
  //             />
  //           </div>
  //           <div className="form-group">
  //             <Label.Root className="form-label">Email Address</Label.Root>
  //             <input
  //               type="email"
  //               name="email"
  //               className="form-input"
  //               value={profile.email}
  //               onChange={handleProfileChange}
  //               required
  //             />
  //           </div>
  //           <div className="form-footer">
  //             <button type="submit" className="update-button">
  //               Update
  //             </button>
  //           </div>
  //         </form>
  //       </div>
  //     </div>

  //     <div className="security-section">
  //       <h3 className="security-title">Security</h3>
        
  //       <div className="security-card">
  //         <div className="security-content">
  //           <Lock size={20} />
  //           <div>
  //             <div className="security-text">Change your password</div>
  //             <div className="security-description">Use a strong password to login to your Calk account.</div>
  //           </div>
  //         </div>
  //         <button className="button" onClick={() => setShowPasswordModal(true)}>
  //           Change Password
  //         </button>
  //       </div>

  //       <div className="security-card">
  //         <div className="security-content">
  //           <Users size={20} />
  //           <div>
  //             <div className="security-text">Two-factor authentication</div>
  //             <div className="security-description">Add additional verification to your account.</div>
  //           </div>
  //         </div>
  //         <button className="button soon">Coming Soon</button>
  //       </div>
  //     </div>
  //   </>
  // );

  const BillingContent = () => (
    <div>
      <h2>Subscription Details</h2>
      {/* Add your subscription management UI here */}
    </div>
  );

  const SettingsContent = () => (
    <div className="space-y-8">
      <div>
        <h3 className="security-title">Download My Data</h3>
        <p className="security-description mb-4">Download all your account data and information</p>
        <button 
          className={`button-settings ${isDownloading ? 'disabled' : ''}`} 
          onClick={handleDownload}
          disabled={isDownloading}
        >
          <Download className={isDownloading ? 'opacity-50' : ''} />&nbsp;
          {isDownloading ? 'Downloading...' : 'Download Data'}
        </button>
      </div>

      <div>
        <h3 className="security-title">Delete Account</h3>
        <p className="security-description mb-4">Permanently delete your account and all associated data</p>
        <AlertDialog.Root>
          <AlertDialog.Trigger asChild>
            <button className="button-settings red">
              <Trash2 />&nbsp;
              Delete Account
            </button>
          </AlertDialog.Trigger>
          <AlertDialog.Portal>
            <AlertDialog.Overlay className="modal-overlay" />
            <AlertDialog.Content className="alert-content-account">
              <AlertDialog.Title className="alert-title">
                Delete Account
              </AlertDialog.Title>
              <AlertDialog.Description className="alert-description">
                This action cannot be undone. This will permanently delete your account
                and remove your data from our servers.
              </AlertDialog.Description>
              <div className="alert-buttons">
                <AlertDialog.Cancel asChild>
                  <button className="alert-button cancel">
                    Cancel
                  </button>
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <button className="alert-button delete" onClick={handleDeleteAccount}>
                    Delete Account
                  </button>
                </AlertDialog.Action>
              </div>
            </AlertDialog.Content>
          </AlertDialog.Portal>
        </AlertDialog.Root>
      </div>
    </div>
  );

  return (
    <div className="account-container">
      <Tabs.Root defaultValue="account">
        <Tabs.List className="tabs-list">
          <Tabs.Trigger className="tab-trigger" value="account">Account</Tabs.Trigger>
          {/*<Tabs.Trigger className="tab-trigger" value="billing">Billing</Tabs.Trigger>*/}
          <Tabs.Trigger className="tab-trigger" value="settings">Settings</Tabs.Trigger>
        </Tabs.List>

        <div className="content-panel">
          <Tabs.Content value="account">
            <AccountContent 
              profile={profile}
              handleProfileChange={handleProfileChange}
              handleProfileUpdate={handleProfileUpdate}
              setShowPasswordModal={setShowPasswordModal}
            />
          </Tabs.Content>

          <Tabs.Content value="billing">
            <BillingContent />
          </Tabs.Content>

          <Tabs.Content value="settings">
            <SettingsContent />
          </Tabs.Content>
        </div>
      </Tabs.Root>

      <Dialog.Root open={showPasswordModal} onOpenChange={setShowPasswordModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="modal-overlay">
            <Dialog.Content className="modal-content">
              <div className="dialog-header">
                <Dialog.Title className="dialog-title">Change Password</Dialog.Title>
              </div>
              <form onSubmit={handlePasswordChange}>
                <div className="form-group-account">
                  <label className="form-label">Current Password</label>
                  <input
                    type="password"
                    className="form-input"
                    value={passwords.current}
                    onChange={(e) => setPasswords({...passwords, current: e.target.value})}
                  />
                </div>
                <div className="form-group-account">
                  <label className="form-label">New Password</label>
                  <input
                    type="password"
                    className="form-input"
                    value={passwords.new}
                    onChange={(e) => setPasswords({...passwords, new: e.target.value})}
                  />
                </div>
                <div className="form-group-account">
                  <label className="form-label">Confirm New Password</label>
                  <input
                    type="password"
                    className="form-input"
                    value={passwords.confirm}
                    onChange={(e) => setPasswords({...passwords, confirm: e.target.value})}
                  />
                </div>
                <div className="dialog-footer">
                  <button 
                    type="button" 
                    className="button" 
                    onClick={() => setShowPasswordModal(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="button">
                    Update Password
                  </button>
                </div>
              </form>
              <Dialog.Close asChild>
                <button className="dialog-close" aria-label="Close">
                  ×
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>

      {error && <div className="mt-4 p-4 bg-red-50 text-red-600 rounded error-message">{error}</div>}
      {success && <div className="mt-4 p-4 bg-green-50 text-green-600 rounded success-message">{success}</div>}
    </div>
  );
};

export default MyAccount;