import React from 'react';
import * as HoverCard from '@radix-ui/react-hover-card';
import { Card, Text, Flex, Box } from '@radix-ui/themes';
import { Plus, Minus } from 'lucide-react';

const ModelInfoHoverCard = ({ model }) => (
  <HoverCard.Root>
    <HoverCard.Trigger asChild>
      <button className="info-button">i</button>
    </HoverCard.Trigger>
    <HoverCard.Portal>
      <HoverCard.Content sideOffset={5} className="radix-hover-content" side="right">
        <Card className="radix-hover-card">
          {/* Header with icon and name */}
          <Flex gap="3" className="radix-model-header">
            <img 
              src={`/images/${model.icon}`} 
              alt={model.name}
              className="model-icon-small"
            />
            <Flex direction="column">
              <Text className="company-name">{model.creatorCompany}</Text>
              <Text className="hover-card-model-name">{model.name}</Text>
            </Flex>
          </Flex>

          {/* Description */}
          <Text className="model-description">{model.description}</Text>

          {/* Divider */}
          <div className="divider" />

          {/* Ratings */}
          <div className="ratings-container">
            <div className="rating-row">
              <span className="rating-label">INTELLIGENCE</span>
              <div className="rating-bars">
                {[...Array(5)].map((_, i) => (
                  <div 
                    key={i} 
                    className={`rating-bar ${i < model.intelligence ? 'filled' : ''}`} 
                  />
                ))}
              </div>
            </div>
            <div className="rating-row">
              <span className="rating-label">SPEED</span>
              <div className="rating-bars">
                {[...Array(5)].map((_, i) => (
                  <div 
                    key={i} 
                    className={`rating-bar ${i < model.speed ? 'filled' : ''}`} 
                  />
                ))}
              </div>
            </div>
            <div className="rating-row">
              <span className="rating-label">COST</span>
              <div className="rating-bars">
                {[...Array(5)].map((_, i) => (
                  <div 
                    key={i} 
                    className={`rating-bar ${i < model.cost ? 'filled' : ''}`} 
                  />
                ))}
              </div>
            </div>
            <div className="rating-row">
              <span className="rating-label">CREATIVITY</span>
              <div className="rating-bars">
                {[...Array(5)].map((_, i) => (
                  <div 
                    key={i} 
                    className={`rating-bar ${i < model.creativity ? 'filled' : ''}`} 
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Divider */}
          <div className="divider" />

          {/* Strengths */}
          <Text className="strength-text">CAPACITIES</Text>
          <Flex gap="2" wrap="wrap" mb="4">
            {model.tags.map((tag, index) => (
              <span 
                key={index} 
                className={`tag ${tag.toLowerCase().replace(' ', '-')}`}
              >
                {tag}
              </span>
            ))}
          </Flex>

          {/* Divider */}
          <div className="divider" />

          {/* Best uses */}
          <Text className="best-uses-label">{model.name} is best for:</Text>
          <Flex gap="2" wrap="wrap">
            {model.useCases.map((useCase, index) => (
              <span key={index} className="use-case">
                {useCase}
              </span>
            ))}
          </Flex>
        </Card>
        <HoverCard.Arrow className="hover-card-arrow" />
      </HoverCard.Content>
    </HoverCard.Portal>
  </HoverCard.Root>
);

export default ModelInfoHoverCard;