import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import HomeChat from '../pages/HomeChat';
import './Sidebar.css';
import { SlNote } from "react-icons/sl";
import { IoHome } from "react-icons/io5";
import { FaBuilding } from "react-icons/fa";
import { FaBrain, FaFileImport, FaDatabase, FaBookOpen } from "react-icons/fa6";
import { MdSupportAgent, MdOutlineHelp, MdChatBubbleOutline } from "react-icons/md";
import { VscDebugDisconnect, VscFeedback } from "react-icons/vsc";
import { GoProjectRoadmap } from "react-icons/go";
import { FaRocketchat, FaRegFolderOpen, FaPlay, FaSlack, FaTools, FaUser, FaUserFriends, FaUserCog, FaRobot, FaLayerGroup, FaPlayCircle, FaChartBar } from "react-icons/fa";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Collapsible from '@radix-ui/react-collapsible';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Card, Button, AlertDialog, Flex } from '@radix-ui/themes';
import { LucideCloudLightning } from 'lucide-react';
import Swal from 'sweetalert2'

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, userEmail, userFirstName, userLastName, isSuperAdmin } = useAuth();
  const [recentChats, setRecentChats] = useState([]);
  const [isChatsOpen, setIsChatsOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [showContextCard, setShowContextCard] = useState(false);
  // subscriptionStatus

  useEffect(() => {
    fetchRecentChats();
  }, []);

  const fetchRecentChats = async () => {
    try {
      // Fetch both regular and agent chats
      const [regularChatsResponse, agentChatsResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BACKEND}/api/chat/recents`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        }),
        axios.get(`${process.env.REACT_APP_BACKEND}/api/agents/recent-chats`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        })
      ]);

      // Combine and sort chats by updatedAt, adding a type identifier
      const allChats = [
        ...regularChatsResponse.data.map(chat => ({ ...chat, chatType: 'regular' })),
        ...agentChatsResponse.data.map(chat => ({ ...chat, chatType: 'agent' }))
      ].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

      setRecentChats(allChats);
      // console.log(allChats);
    } catch (error) {
      console.error('Error fetching recent chats:', error);
    }
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/team/company-details`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });
        
        setCompanyDetails(response.data);
        
        // Check if any required field is empty
        const hasEmptyField = [
          'companyName',
          'companyIndustry',
          'companySize',
          'businessModel',
          'productDescription',
          'targetMarket',
          'businessChallenge',
          'shortTermGoal',
          'technologyLevel',
          'geographicScope',
          'growthStage'
        ].some(field => !response.data[field]);
        
        setShowContextCard(hasEmptyField);
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyDetails();
  }, []);

  const handleChatSelect = (chat, e) => {
    e.preventDefault();
    
    // Determine the correct route based on chat type
    const route = chat.chatType === 'agent' 
      ? `/home/agents/${chat._id}`
      : `/home/chat/${chat._id}`;
      
    navigate(route);
  };

  const getModelIcon = (modelName) => {
    const models = [
      { name: 'GPT 4', icon: 'gpt.png' },
      { name: 'GPT 4o', icon: 'gpt.png' },
      { name: 'GPT 4o Mini', icon: 'gpt.png' },
      { name: 'Claude 3.5', icon: 'claude.png' },
      { name: 'Claude Sonnet', icon: 'claude.png' },
      { name: 'Claude Opus', icon: 'claude.png' },
      { name: 'Claude Haiku', icon: 'claude.png' },
      { name: 'Huggin Face', icon: 'huggin.png' },
      { name: 'Mistral AI Large', icon: 'mistral.png' },
      { name: 'Mistral AI Medium', icon: 'mistral.png' },
      { name: 'Mistral AI Small', icon: 'mistral.png' },
      { name: 'Codestral', icon: 'mistral.png' },
      { name: 'Llama 3.1', icon: 'llama.png' },
      { name: 'Gemini 1.5', icon: 'gemini.png' },
      { name: 'Cohere', icon: 'cohere.png' },
      { name: 'Multi Modal', icon: 'multimodel.png' },
      { name: 'Perplexity', icon: 'perplexity.png' },
      { name: 'Stable Diffusion', icon: 'stablediffusion.png' },
      { name: 'DALL-E 3', icon: 'gpt.png' },
    ];

    const model = models.find(m => m.name.toLowerCase() === modelName.toLowerCase());
    if (model && model.icon) {
      return `/images/all_icons/${model.icon}`;
    }
    return '/images/default.png';
  };

  const getAgentIcon = (chat) => {
    if (chat.agentId && typeof chat.agentId === 'object' && chat.agentId.icon) {
      return `/images/all_icons/${chat.agentId.icon}`;
    }
    return '/images/all_icons/default-agent-icon.png';
  };

  const getEntityIcon = (chat) => {
    if (chat.chatType === 'agent' && chat.agentId) {
      // Handle agent icons
      if (typeof chat.agentId === 'object' && chat.agentId.icon) {
        return `/images/all_icons/${chat.agentId.icon}`;
      }
      return '/images/all_icons/default-agent-icon.png';
    } else {
      // Handle model icons
      const models = [
        { name: 'GPT 4', icon: 'gpt' },
        { name: 'GPT 4o', icon: 'gpt' },
        { name: 'GPT 4o Mini', icon: 'gpt' },
        { name: 'Claude 3.5', icon: 'claude' },
        { name: 'Claude Sonnet', icon: 'claude' },
        { name: 'Claude Opus', icon: 'claude' },
        { name: 'Claude Haiku', icon: 'claude' },
        { name: 'Huggin Face', icon: 'huggin' },
        { name: 'Mistral AI Large', icon: 'mistral' },
        { name: 'Mistral AI Medium', icon: 'mistral' },
        { name: 'Mistral AI Small', icon: 'mistral' },
        { name: 'Codestral', icon: 'mistral' },
        { name: 'Llama 3.1', icon: 'llama' },
        { name: 'Gemini 1.5', icon: 'gemini' },
        { name: 'Perplexity', icon: 'perplexity' },
        { name: 'Cohere', icon: 'cohere' },
        { name: 'Stable Diffusion', icon: 'stablediffusion' },
        { name: 'DALL-E 3', icon: 'gpt' }
      ];

      const model = models.find(m => m.name.toLowerCase() === chat.aiModel?.toLowerCase());
      return model ? `/images/all_icons/${model.icon}.png` : '/images/default.png';
    }
  };

  const handleDeleteChat = async (chatId, e, chatType) => {
    // e.preventDefault();
    // e.stopPropagation();
    try {
      const endpoint = chatType === 'agent'
        ? `${process.env.REACT_APP_BACKEND}/api/agents/${chatId}/chat/${chatId}`
        : `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}`;

      await axios.delete(endpoint, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      
      fetchRecentChats();
      
      // Clear selection if the deleted chat was selected
      if (selectedChat && selectedChat._id === chatId) {
        setSelectedChat(null);
        setSelectedModel(null);
        setSelectedIcon(null);
        navigate('/home');
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
    Swal.fire({
      position: "top-end",
      icon: "info",
      title: "Logout successful",
      showConfirmButton: false,
      timer: 1000
    });
  };

  const getRecentChats = () => {
    fetchRecentChats();
  }

  function refreshPage() {
      setTimeout(()=>{
          window.location.reload(false);
      }, 5);
      console.log('page to reload')
  }

  const handleGiveContext = () => {
    navigate('/company-info');
  };

  if (selectedModel && selectedChat) {
    const entity = {
      type: selectedChat.chatType,
      model: selectedModel,
      icon: selectedIcon,
      id: selectedChat._id
    };

    return <HomeChat 
      entity={entity}
      chatId={selectedChat._id}
    />;
  }

  return (
    <nav className="sidebar">
      <div className="sidebar-top">
        {/* Logo */}
        <div className="logo">
          <img src="/calk-name.png" alt="Calk"  />
        </div>
        {showContextCard && (
          <div className={`context-card-container ${isChatsOpen ? 'behind' : ''}`}>
            <Card className="context-card">
              <div className="context-card-icon">
                <LucideCloudLightning size={24} />
              </div>
              <div className="context-card-content">
                <h3>Let AI know your business context</h3>
                <button 
                  className="context-button" 
                  onClick={handleGiveContext}
                >
                  Give context
                </button>
              </div>
            </Card>
          </div>
        )}
        {/* Main Navigation */}
        <div className="nav-links">
          {/*<Link to="/chats" className={location.pathname === '/dashboard' ? 'active' : ''}>
            <IoHome />
            <span>Home</span>
          </Link>*/}


          <Link to="/home" onClick={refreshPage} className={location.pathname === '/home' ? 'active' : ''}>
            <IoHome />
            <span>Home</span>
          </Link>
          
          <Link to="/agents" className={location.pathname === '/agents' ? 'active' : ''}>
            <MdSupportAgent />
            <span>Agents</span>
          </Link>
          
          <Link to="/knowledge" className={location.pathname === '/knowledge' ? 'active' : ''}>
            <FaDatabase />
            <span>Knowledge</span>
          </Link>

          <div className="chat-section">
            <Collapsible.Root open={isChatsOpen} onOpenChange={setIsChatsOpen}>
              <Collapsible.Trigger className={`chat-trigger ${isChatsOpen ? 'active' : ''}`} onClick={fetchRecentChats}>
                <MdChatBubbleOutline />
                <span>Recent Chats</span>
                <svg 
                  className={`arrow ${isChatsOpen ? 'open' : ''}`}
                  width="16" 
                  height="16" 
                  viewBox="0 0 16 16"
                >
                  <path d="M6 12l4-4-4-4" stroke="currentColor" fill="none" strokeWidth="2"/>
                </svg>
              </Collapsible.Trigger>
              <Collapsible.Content>
                <div className="chat-items-container">
                  {recentChats.map(chat => (
                    <div key={chat._id} className="chat-item-wrapper">
                      <Link 
                        to={chat.chatType === 'agent' ? `/home/agents/${chat._id}` : `/home/chat/${chat._id}`}
                        className={`chat-item ${
                          location.pathname === (chat.chatType === 'agent' ? `/home/agents/${chat._id}` : `/home/chat/${chat._id}`) 
                          ? 'active' 
                          : ''
                        }`}
                      >
                        <div className="chat-item-header">
                          <img 
                            src={chat.chatType === 'agent' ? getAgentIcon(chat) : getModelIcon(chat.aiModel)}
                            alt={chat.title}
                            className="chat-icon"
                          />
                          <div className="chat-title">
                            {chat.title.length > 18 ? `${chat.title.slice(0, 15)}...` : chat.title}
                          </div>
                        </div>
                      </Link>
                      <AlertDialog.Root>
                        <AlertDialog.Trigger>
                          <button className="delete-chat-btn">×</button>
                        </AlertDialog.Trigger>
                        
                        <AlertDialog.Content>
                          <AlertDialog.Title>Delete the conversation?</AlertDialog.Title>
                          <AlertDialog.Description>
                            Are you sure you want to delete <b>{chat.title}</b>? This chat will no longer be accessible and you will not be able to retrieve it.
                          </AlertDialog.Description>
                          
                          <Flex gap="3" mt="4" justify="end">
                            <AlertDialog.Cancel>
                              <Button variant="soft" color="gray">
                                Cancel
                              </Button>
                            </AlertDialog.Cancel>
                            <AlertDialog.Action>
                              <Button 
                                color="red"
                                onClick={() => handleDeleteChat(chat._id, chat.chatType)}
                              >
                                Delete
                              </Button>
                            </AlertDialog.Action>
                          </Flex>
                        </AlertDialog.Content>
                      </AlertDialog.Root>
                    </div>
                  ))}
                </div>
              </Collapsible.Content>
            </Collapsible.Root>
          </div>
        </div>
      </div>

      {/* Footer Navigation */}
      <div className="sidebar-bottom">
        <div className="nav-links">
          {/*<Link to="/academy">
            <FaPlayCircle />
            <span>Calk Academy</span>
          </Link> */}
          
          <a 
            href="https://docs.calk-ai.com/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="external-link"
          >
            <FaBookOpen />
            <span>Documentation</span>
          </a>
          
          <a 
            href="https://join.slack.com/t/calkcommunity/shared_invite/zt-2rr3y121v-2L6KQ3l9tC8l47mkwUcJ8Q" 
            target="_blank" 
            rel="noopener noreferrer"
            className="external-link"
          >
            <FaSlack />
            <span>Community</span>
          </a>

          <a 
            href="https://tally.so/r/w88pel" 
            target="_blank" 
            rel="noopener noreferrer"
            className="external-link"
          >
            <VscFeedback />
            <span>Feedback</span>
          </a>
        </div>

        {/* User Profile Card with Dropup Menu */}
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <div className="user-card-container">
              <div className="user-card">
                <div className="avatar-container-sidebar">
                  {userFirstName?.[0]}{userLastName?.[0]}
                </div>
                <div className="user-details">
                  <div className="user-name">{userFirstName} {userLastName}</div>
                  <div className="user-email">{userEmail}</div>
                </div>
              </div>
            </div>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content 
              className="dropup-content"
              sideOffset={5}
              align="center"
              side="top"
            >
              <Link to="/my-account" className="dropup-item">
                <FaUser className="menu-icon" />
                <span>My Account</span>
              </Link>
              
              {/*<Link to="/team" className="dropup-item">
                <FaUserFriends className="menu-icon" />
                <span>Team</span>
              </Link>*/}

              {isSuperAdmin && (
                <Link to="/agents/create/admin" className="dropup-item">
                  <MdSupportAgent className="menu-icon" />
                  <span>Create Calk Agent</span>
                </Link>
              )}
              
              <Link to="/integrations" className="dropup-item">
                <VscDebugDisconnect className="menu-icon" />
                <span>Integrations</span>
              </Link>
              
              <Link to="/company-info" className="dropup-item">
                <FaBuilding className="menu-icon" />
                <span>Company Info</span>
              </Link>

               {/*<Link to="/analytics" className="dropup-item">
                <FaChartBar className="menu-icon" />
                <span>Analytics</span>
              </Link>*/}
              
              <DropdownMenu.Separator className="dropup-separator" />
              
              <DropdownMenu.Item 
                className="dropup-item text-red-600" 
                onClick={handleLogout}
              >
                Logout
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </nav>
  );
};

export default Sidebar;

      // {subscriptionStatus === 'expired' && (
      //   <div className="subscription-warning">
      //     Your subscription has expired. Some features may be limited. 
      //     <Link to="/settings">Renew Now</Link>
      //   </div>
      // )}

// <Link to="/settings" className={`sidebar-item ${location.pathname === '/settings' ? 'active' : ''}`}><FaUserCog />&nbsp;&nbsp;Settings</Link>

      // <div className="sidebar-section">
      //   <h3 className="sidebar-title">Monitor</h3>
      //   <Link to="/activity-center" className={`sidebar-item ${location.pathname === '/activity-center' ? 'active' : ''}`}>Activity Center</Link>
      //   <Link to="/analytics" className={`sidebar-item ${location.pathname === '/analytics' ? 'active' : ''}`}>Analytics</Link>
      // </div>

        // <Link to="/tutorials" className={`sidebar-item ${location.pathname === '/tutorials' ? 'active' : ''}`}><FaPlay />&nbsp;&nbsp;Tutorials</Link>
        // <Link to="/documentation" className={`sidebar-item ${location.pathname === '/documentation' ? 'active' : ''}`}><FaBookOpen />&nbsp;&nbsp;Documentation</Link>


      // <div className="sidebar-section">
      //   <h3 className="sidebar-title">Tools</h3>
      //   <Link to="/prompts" className={`sidebar-item ${location.pathname === '/prompts' ? 'active' : ''}`}><SlNote />&nbsp;&nbsp;Prompts</Link>
      //   <Link to="/finetune" className={`sidebar-item ${location.pathname === '/finetune' ? 'active' : ''}`}><FaDatabase />&nbsp;&nbsp;Finetune</Link>
      //   <Link to="/rag" className={`sidebar-item ${location.pathname === '/rag' ? 'active' : ''}`}><FaFileImport />&nbsp;&nbsp;RAG</Link>
      // </div>        