import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import * as Dialog from '@radix-ui/react-dialog';
import { MoreHorizontal, UserPlus, UserMinus, Shield } from 'lucide-react';
import './Team.css';
import Swal from 'sweetalert2'

const Team = () => {
  const { userFirstName, userLastName, userEmail, userRole } = useAuth();
  const [teamMembers, setTeamMembers] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newMember, setNewMember] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: 'User',
    password: ''
  });
  const [newEmail, setNewEmail] = useState('');
  const [memberToDelete, setMemberToDelete] = useState(null);

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/team/members`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTeamMembers(response.data);
    } catch (error) {
      console.error('Error fetching team members:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewMember((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddMember = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth/create-team-member`, 
        newMember,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.member) {
        setTeamMembers((prevState) => [...prevState, response.data.member]);
        setNewMember({
          firstName: '',
          lastName: '',
          email: '',
          role: 'User',
          password: ''
        });
        setIsDialogOpen(false);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Member added successfully!",
          showConfirmButton: false,
          timer: 1500
        });
        
      }
    } catch (error) {
      console.error('Error adding team member:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: "Error adding team member!",
        showConfirmButton: false,
        timer: 3000
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }
  };

  const handleUpdateRole = async (memberId, newRole) => {
    if (userRole !== 'Administrator') return;
    try {
      const token = localStorage.getItem('token');
      const response = await axios.patch(`${process.env.REACT_APP_BACKEND}/api/team/members/${memberId}`, 
        { role: newRole },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.member) {
        setTeamMembers(prevMembers => 
          prevMembers.map(member => 
            member._id === memberId ? response.data.member : member
          )
        );
      }
    } catch (error) {
      console.error('Error updating member role:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: "Error updating member role!",
        showConfirmButton: false,
        timer: 3000
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }
  };

  const handleDeleteMember = async (memberId) => {
    if (userRole !== 'Administrator') return;
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/team/members/${memberId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTeamMembers(prevMembers => prevMembers.filter(member => member._id !== memberId));
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Member deleted successfully!",
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error deleting team member:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: "Error deleting team member!",
        showConfirmButton: false,
        timer: 3000
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }
  };

  const getAvatarColor = (index) => `avatar-${index % 8}`;

  const getInitials = (firstName, lastName) => {
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
  };

  return (
    <div className="team-container">
      <div className="team-content-panel">
        <div className="header">
          <h1 className="title">Your Team</h1>
          <p className="description">Invite and manage your team members.</p>
        </div>

        <div className="team-invite-form">
          <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <Dialog.Trigger asChild>
              <button className="team-invite-button">
                <UserPlus className="w-4 h-4" />
                Invite
              </button>
            </Dialog.Trigger>
            
            <Dialog.Portal>
              <Dialog.Overlay className="team-dialog-overlay" />
              <Dialog.Content className="team-dialog-content">
                <Dialog.Title className="team-dialog-title">
                  Add New Team Member
                </Dialog.Title>
                <form className="team-dialog-form">
                  <input
                    type="text"
                    name="firstName"
                    value={newMember.firstName}
                    onChange={handleInputChange}
                    placeholder="First Name"
                    className="team-dialog-input"
                    required
                  />
                  <input
                    type="text"
                    name="lastName"
                    value={newMember.lastName}
                    onChange={handleInputChange}
                    placeholder="Last Name"
                    className="team-dialog-input"
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    value={newMember.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    className="team-dialog-input"
                    required
                  />
                  <input
                    type="password"
                    name="password"
                    value={newMember.password}
                    onChange={handleInputChange}
                    placeholder="Password"
                    className="team-dialog-input"
                    required
                  />
                  <select
                    name="role"
                    value={newMember.role}
                    onChange={handleInputChange}
                    className="team-dialog-input"
                  >
                    <option value="Administrator">Administrator</option>
                    <option value="User">User</option>
                  </select>
                  <div className="team-dialog-buttons">
                    <button 
                      type="button" 
                      onClick={() => setIsDialogOpen(false)}
                      className="team-dialog-button cancel"
                    >
                      Cancel
                    </button>
                    <button 
                      type="button" 
                      onClick={handleAddMember}
                      className="team-dialog-button submit"
                    >
                      Add Member
                    </button>
                  </div>
                </form>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </div>

        <div className="team-list">
          {teamMembers.map((member, index) => (
            <div key={member._id} className="member-row">
              <div className="member-info">
                <div className={`avatar ${getAvatarColor(index)}`}>
                  {getInitials(member.firstName, member.lastName)}
                </div>
                <div className="member-details">
                  <span className="member-name">{member.firstName} {member.lastName}</span>
                  <span className="member-email">{member.email}</span>
                </div>
              </div>
              
              <span className="member-role">{member.role}</span>

              {userRole === 'Administrator' && member.email !== userEmail && (
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild>
                    <button className="dropdown-trigger">
                      ...
                    </button>
                  </DropdownMenu.Trigger>

                  <DropdownMenu.Portal>
                    <DropdownMenu.Content className="team-dropdown-content" align="end">
                      <DropdownMenu.Item className="team-dropdown-item" onClick={() => handleUpdateRole(member._id, member.role === 'Administrator' ? 'User' : 'Administrator' )}>
                        <Shield className="w-4 h-4" />
                        {member.role === 'Administrator' ? 'Make User' : 'Make Admin'}
                      </DropdownMenu.Item>
                      
                      <DropdownMenu.Item className="team-dropdown-item delete" onClick={() => setMemberToDelete(member)}>
                        <UserMinus className="w-4 h-4" />
                        Delete Member 
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu.Root>
              )}
            </div>
          ))}
        </div>
      </div>

      <AlertDialog.Root open={!!memberToDelete} onOpenChange={() => setMemberToDelete(null)}>
        <AlertDialog.Portal>
          <AlertDialog.Overlay className="alert-overlay" />
          <AlertDialog.Content className="alert-content">
            <AlertDialog.Title className="alert-title">
              Delete Team Member
            </AlertDialog.Title>

            <AlertDialog.Description className="alert-description">
              Are you sure you want to remove {memberToDelete?.firstName} {memberToDelete?.lastName} from the team? 
              This action cannot be undone.
            </AlertDialog.Description>

            <div className="alert-buttons">
              <AlertDialog.Cancel asChild>
                <button className="alert-button cancel">
                  Cancel
                </button>
              </AlertDialog.Cancel>

              <AlertDialog.Action asChild>
                <button 
                  className="alert-button delete"
                  onClick={() => {
                    if (memberToDelete) {
                      handleDeleteMember(memberToDelete._id);
                      setMemberToDelete(null);
                    }
                  }}
                >
                  Delete Member
                </button>
              </AlertDialog.Action>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  );
};

export default Team;