import React, { useState, useEffect } from 'react';

const HomeHeader = ({ userFirstName }) => {
  const [greeting, setGreeting] = useState('');

  const greetings = [
    "Hola, amigo! ¿Qué pasa? 🌮🇲🇽",
    "Bonjour, champion! Ready to crush it? 🥖🇫🇷",
    "Oye, parce! Let's make magic. 🦄🪄",
    "Ça va, boss? Let's dive in! 🐠🌊",
    `Vive la révolution, ${userFirstName}! 💥🇫🇷`,
    "Time for some fiesta vibes, eh? 🍹🎉",
    `Aloha, ${userFirstName}! What's the mission today? 🌺🤙`,
    "Bonjour, mon ami! Ready to slay the day? 🗼💪",
    `Let's taco 'bout your ideas, ${userFirstName}! 🌮✨`,
    "Salut, génie! What can I brew for you? 🧙‍♂️☕️",
    `Rock on, ${userFirstName}! 🤘🎸`,
    "Amigo, it's salsa time—your move! 💃🔥",
    "Ready to Eiffel something amazing? 🗼🚀",
    "Vamos, campeón! The world's waiting. 🌎🥇",
    "Yeehaw, partner! Let's saddle up! 🤠🐎"
  ];

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * greetings.length);
    setGreeting(greetings[randomIndex]);
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div className="chat-header">
      <h1>
        {/*<img src="/favicon-calk.svg" className="chat-logo" alt="Chat logo" />*/}
        {greeting}
      </h1>
    </div>
  );
};

export default HomeHeader;