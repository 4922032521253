import React, { useState, useEffect } from 'react';
import './Integrations.css';
import { Box, Card, Text, Button, Flex } from '@radix-ui/themes';
import { FaLink, FaUnlink, FaCheck } from 'react-icons/fa';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack, IoSettingsSharp } from "react-icons/io5";
import Swal from 'sweetalert2'

const Integrations = () => {
  const navigate = useNavigate();
  const [connectedServices, setConnectedServices] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const integrations = [
    { 
      name: 'Google Drive', 
      icon: 'google-drive.png', 
      key: 'googleDrive',
      description: 'Import your files and collaborate seamlessly with your team. Access documents anywhere, anytime.' 
    },
    // { 
    //   name: 'Google Calendar', 
    //   icon: 'google-calendar.png', 
    //   key: 'googleCalendar',
    //   description: 'List your meetings efficiently with our powerful calendar feature.' 
    // },
    // { 
    //   name: 'Gmail', 
    //   icon: 'gmail.png', 
    //   key: 'gmail',
    //   description: 'Connect your email account to list your mails directly from the Chat Interface.' 
    // },
    // { 
    //   name: 'OneDrive', 
    //   icon: 'onedrive.png', 
    //   key: 'oneDrive',
    //   description: 'Access and share your files from anywhere with Microsoft OneDrive integration.',
    //   alert: 'This is for Microsoft personnal accounts only.'
    // },
    { 
      name: 'Notion', 
      icon: 'notion.png', 
      key: 'notion',
      description: 'Connect to your Notion workspace for seamless knowledge management.' 
    },
    // { 
    //   name: 'Slack', 
    //   icon: 'slack.png', 
    //   key: 'slack',
    //   description: 'List your recent messages and conversations.' 
    // }
  ];

  useEffect(() => {
    checkIntegrationStatuses();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get('success');
    const error = queryParams.get('error');

    if (success === 'true') {
      console.log('OAuth successful');
      checkIntegrationStatuses();
    } else if (error === 'true') {
      console.error('OAuth failed');
    }
  }, [location]);


  const checkIntegrationStatuses = async () => {
    try {
      const [googleResponse, googleCalendarResponse, gmailResponse, onedriveResponse, notionResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BACKEND}/api/google/status`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }),
        axios.get(`${process.env.REACT_APP_BACKEND}/api/google/calendar/status`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }),
        axios.get(`${process.env.REACT_APP_BACKEND}/api/google/gmail/status`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }),
        axios.get(`${process.env.REACT_APP_BACKEND}/api/onedrive/status`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }),
        axios.get(`${process.env.REACT_APP_BACKEND}/api/notion/status`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
      ]);

      setConnectedServices({
        googleDrive: googleResponse.data.connected,
        googleCalendar: googleCalendarResponse.data.connected,
        gmail: gmailResponse.data.connected,
        oneDrive: onedriveResponse.data.connected,
        notion: notionResponse.data.connected
      });
    } catch (error) {
      console.error('Error checking integration statuses:', error);
    }
  };

  const handleConnect = async (service) => {
    try {
      setIsLoading(true);
      let response;
      if (service.key === 'googleDrive') {
        response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/google/auth`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
      } else if (service.key === 'googleCalendar') {
        response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/google/auth-googlecalendar`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
      } else if (service.key === 'gmail') {
        response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/google/auth-gmail`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
      } else if (service.key === 'oneDrive') {
        response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/onedrive/auth`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
      } else if (service.key === 'notion') {
        response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/notion/auth`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
      }
      window.location.href = response.data.url;
    } catch (error) {
      console.error(`Error initiating ${service.name} connection:`, error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: `Error initiating ${service.name} connection.`,
        showConfirmButton: false,
        timer: 2000
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisconnect = async (service) => {
    try {
      setIsLoading(true);
      if (service.key === 'googleDrive') {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/google/disconnect`,
          {},
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
      } else if (service.key === 'googleCalendar') {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/google/calendar/disconnect`,
          {},
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
      } else if (service.key === 'gmail') {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/google/gmail/disconnect`,
          {},
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
      } else if (service.key === 'oneDrive') {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/onedrive/disconnect`,
          {},
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
      } else if (service.key === 'notion') {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/notion/disconnect`,
          {},
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
      }
      setConnectedServices(prev => ({ ...prev, [service.key]: false }));
    } catch (error) {
      console.error(`Error disconnecting ${service.name}:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="integrations">
      <Flex align="center" mb="6" className="header-container">
        <Button 
          onClick={() => navigate('/knowledge')}
          variant="ghost"
          className="back-button-integrations"
        >
          <Flex align="center" gap="2">
            <IoArrowBack />
            Back to Knowledge
          </Flex>
        </Button>
        <h1>Connect your favorite tools</h1>
      </Flex>
      <div className="integration-grid">
        {integrations.map((integration) => (
          <div key={integration.key} className="integration-card">
            <img
              src={`/images/${integration.icon}`}
              alt={`${integration.name} icon`}
              className="icon-image"
            />
            <h3 className="integration-name">{integration.name}</h3>
            <p className="integration-description">{integration.description}</p>
            <p className="integration-alert">{integration.alert}</p>
            {(integration.key === 'googleDrive' || 
              integration.key === 'googleCalendar' || 
              integration.key === 'gmail' || 
              integration.key === 'oneDrive') ? (
              <button
                className={`connect-button ${connectedServices[integration.key] ? 'connected' : ''}`}
                onClick={() => connectedServices[integration.key] ? 
                  handleDisconnect(integration) : 
                  handleConnect(integration)
                }
                disabled={isLoading}
              >
                {connectedServices[integration.key] ? 'Disconnect' : 'Connect'}
              </button>
            ) : (
              <button className="connect-button coming-soon" disabled>
                Coming Soon
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Integrations;

 // || integration.key === 'notion' 