import React from 'react';

const Documentation = () => {
  return (
    <div className="page-container">
      <h1>Documentation</h1>
      <p>Browse through our comprehensive documentation for detailed information.</p>
    </div>
  );
};

export default Documentation;