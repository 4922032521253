import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Agents.css';
import HomeChat from './HomeChat';
import CreateAgentModal from './CreateAgentModal';
import { GoProjectRoadmap } from "react-icons/go";
import { IoTrashOutline } from "react-icons/io5";
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { TextField } from '@radix-ui/themes';
import * as Form from '@radix-ui/react-form';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

const Agents = () => {
  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [recentAgentChats, setRecentAgentChats] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [fileUploadAgent, setFileUploadAgent] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const { userFirstName } = useAuth();
  const [isStartingChat, setIsStartingChat] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const currentCompanyId = localStorage.getItem('companyId');
  const predefinedTags = ['Business', 'Marketing', 'Productivity', 'Sales', 'Dev', 'HR', 'Data'];
  const tags = [...predefinedTags, 'Miscellaneous'];

  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = agents.filter(agent => 
        agent.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredAgents(filtered);
    } else {
      setFilteredAgents(agents);
    }
  }, [searchTerm, agents]);

  useEffect(() => {
    if (selectedTag) {
      const filtered = agents.filter(agent => {
        const agentTags = Array.isArray(agent.specialization) ? agent.specialization :
                         (typeof agent.specialization === 'string' ? [agent.specialization] : []);
        
        if (selectedTag === 'Miscellaneous') {
          return !agentTags.some(agentTag => 
            predefinedTags.some(predefinedTag => 
              agentTag.toLowerCase() === predefinedTag.toLowerCase()
            )
          );
        }
        
        return agentTags.some(agentTag => 
          agentTag.toLowerCase() === selectedTag.toLowerCase()
        );
      });
      setFilteredAgents(filtered);
    } else {
      setFilteredAgents(agents);
    }
  }, [selectedTag, agents]);

  const handleCloseChat = () => {
    setSelectedChat(null);
  };

  const handleCreateAgent = () => {
    setIsCreateModalOpen(true);
  };

  const handleFetchAllAgents = () => {
    fetchAgents();
  }

  const handleFetchCompanyAgents = () => {
    fetchCompanyAgents();
  }

  const handleFetchTemplatesAgents = () => {
    fetchTemplateAgents();
  }

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleEditAgent = (agent) => {
    navigate(`/agents/edit/${agent._id}`);
  };

  const handleStartChat = async (agent) => {
    setIsStartingChat(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/agents/${agent._id}/chat`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );

      const agentResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/agents/details/${agent._id}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );

      // Format exactly like in initializeExistingChat
      const formattedAgent = {
        ...agentResponse.data,
        entityType: 'agent',
        type: 'agent',
        icon: agent.icon,
        iconPath: `/images/all_icons/${agent.icon}`
      };

      const chatData = {
        chatId: response.data._id,
        agent: formattedAgent,
        isConversationStarted: true,
        currentMode: "agents"
      };

      setSelectedChat(chatData);

      navigate(`/home/agents/${response.data._id}`, { 
        replace: true,
        state: { 
          chatId: response.data._id,
          initialSelectedEntity: formattedAgent
        }
      });
    } catch (error) {
      console.error('Error starting agent chat:', error);
      setIsStartingChat(false);
    }
  };

  const fetchAgents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/agents`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setAgents(response.data);
      setFilteredAgents(response.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const searchAgents = async (term) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/agents/search`, {
        params: { term },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setFilteredAgents(response.data);
    } catch (error) {
      console.error('Error searching agents:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTagClick = (tag) => {
    setSelectedTag(tag === selectedTag ? '' : tag);
  };

  const fetchTemplateAgents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/agents/templates`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const fetchCompanyAgents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/agents/my-agents`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const getAgentIcon = (chat) => {
    if (chat.agentId && typeof chat.agentId === 'object') {
      return chat.agentId.icon || 'default-agent-icon';
    }
    return 'default-agent-icon';
  };

  const handleDeleteAgent = async (agentId) => {

      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND}/api/agents/${agentId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        fetchAgents(); // Refresh the list of agents
      } catch (error) {
        console.error('Error deleting agent:', error);
      }

  };

  const groupAgentsByTags = (agentsList) => {
      const grouped = {};
      // Initialize all tag arrays
      tags.forEach(tag => {
        grouped[tag] = [];
      });

      agentsList.forEach(agent => {
        // Make sure specialization exists and is an array, if not, create empty array
        const agentTags = Array.isArray(agent.specialization) ? agent.specialization : 
                         (typeof agent.specialization === 'string' ? [agent.specialization] : []);

        let matchedAnyTag = false;

        // Check each predefined tag
        predefinedTags.forEach(predefinedTag => {
          // Add agent to each matching category
          if (agentTags.some(agentTag => 
            agentTag.toLowerCase() === predefinedTag.toLowerCase())) {
            grouped[predefinedTag].push(agent);
            matchedAnyTag = true;
          }
        });

        // Add to Miscellaneous if no matches were found
        if (!matchedAnyTag) {
          grouped['Miscellaneous'].push(agent);
        }
      });

      return grouped;
  };

  const getFilteredAgents = () => {
      let filtered = agents;

      // Apply search term filter if exists
      if (searchTerm) {
        filtered = filtered.filter(agent => 
          agent.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      // If a tag is selected, only show agents with that tag
      if (selectedTag) {
        if (selectedTag === 'Miscellaneous') {
          filtered = filtered.filter(agent => {
            const agentTags = Array.isArray(agent.specialization) ? agent.specialization :
                             (typeof agent.specialization === 'string' ? [agent.specialization] : []);
            
            return !agentTags.some(agentTag => 
              predefinedTags.some(predefinedTag => 
                agentTag.toLowerCase() === predefinedTag.toLowerCase()
              )
            );
          });
        } else {
          filtered = filtered.filter(agent => {
            const agentTags = Array.isArray(agent.specialization) ? agent.specialization :
                             (typeof agent.specialization === 'string' ? [agent.specialization] : []);
            
            return agentTags.some(agentTag => 
              agentTag.toLowerCase() === selectedTag.toLowerCase()
            );
          });
        }
      }

      return filtered;
  };

  const handleCardClick = (agent, e) => {
    // Only start chat if we didn't click the dropdown
    if (!e.target.closest('.agent-actions-dropdown')) {
      handleStartChat(agent);
    }
  };

  // match initializeExistingChat's
  if (selectedChat) {
    return (
      <HomeChat
        chatId={selectedChat.chatId}
        initialSelectedEntity={selectedChat.agent}
        isConversationStarted={true}
        type="agent"
      />
    );
  }

  return (
    <div className="agents-page">
      <div>
        <button 
          className="create-agent-button"
          onClick={() => navigate('/agents/create')}
        >
          + Create Agent
        </button>
      </div>
      <header className="agents-header">
        <h1>🕵️ Agent Area</h1>
      </header>
      <div className="search-section">
        <Form.Root className="search-container">
          <Form.Field name="search">
            <Form.Control asChild>
              <input
                className="search-input"
                type="text"
                placeholder="🔎 Search for Agents"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form.Control>
          </Form.Field>
        </Form.Root>

        <div className="tags-container">
          {tags.map(tag => (
            <button
              key={tag}
              className={`tag-button ${selectedTag === tag ? 'active' : ''}`}
              onClick={() => handleTagClick(tag)}
            >
              {tag}
            </button>
          ))}
        </div>
      </div>
      <div className="agents-sections">
        {Object.entries(groupAgentsByTags(getFilteredAgents()))
          .filter(([tag, agents]) => agents.length > 0) // Only show sections with agents
          .filter(([tag]) => !selectedTag || tag === selectedTag) // Filter sections based on selected tag
          .map(([tag, tagAgents]) => (
            <div key={tag} className="agent-section">
              <h2 className="section-title">{tag}</h2>
              <ScrollArea className="agents-scroll-area">
                <div className="agents-row">
                  {tagAgents.map(agent => (
                    <div
                      key={agent._id}
                      className="agent-card"
                      onClick={(e) => handleCardClick(agent, e)}
                    >
                      {agent.companyId === currentCompanyId && (
                        <div className="agent-actions-dropdown" onClick={e => e.stopPropagation()}>
                          <DropdownMenu.Root>
                            <DropdownMenu.Trigger className="dropdown-trigger">
                              ...
                            </DropdownMenu.Trigger>

                            <DropdownMenu.Portal>
                              <DropdownMenu.Content className="dropdown-content" sideOffset={5}>
                                <DropdownMenu.Item className="dropdown-item" onClick={() => handleEditAgent(agent)}>
                                  <GoProjectRoadmap />&nbsp;&nbsp;
                                  Edit Agent
                                </DropdownMenu.Item>
                                
                                <DropdownMenu.Separator className="dropdown-separator" />
                                
                                <DropdownMenu.Item 
                                  className="dropdown-item delete" 
                                  onSelect={(e) => e.preventDefault()}
                                >
                                  <AlertDialog.Root>
                                    <AlertDialog.Trigger className="dropdown-item-trigger">
                                      <IoTrashOutline />
                                      Delete Agent
                                    </AlertDialog.Trigger>
                                    
                                    <AlertDialog.Portal>
                                      <AlertDialog.Overlay className="alert-overlay" />
                                      <AlertDialog.Content className="alert-content">
                                        <AlertDialog.Title className="alert-title">
                                          Delete Agent
                                        </AlertDialog.Title>
                                        
                                        <AlertDialog.Description className="alert-description">
                                          Are you sure you want to delete this agent? This action cannot be undone.
                                          All the knowledge and Chat history will be lost.
                                        </AlertDialog.Description>
                                        
                                        <div className="alert-buttons">
                                          <AlertDialog.Cancel asChild>
                                            <button className="alert-button cancel">
                                              Cancel
                                            </button>
                                          </AlertDialog.Cancel>
                                          
                                          <AlertDialog.Action asChild>
                                            <button 
                                              className="alert-button delete"
                                              onClick={() => handleDeleteAgent(agent._id)}
                                            >
                                              Delete
                                            </button>
                                          </AlertDialog.Action>
                                        </div>
                                      </AlertDialog.Content>
                                    </AlertDialog.Portal>
                                  </AlertDialog.Root>
                                </DropdownMenu.Item>
                              </DropdownMenu.Content>
                            </DropdownMenu.Portal>
                          </DropdownMenu.Root>
                        </div>
                      )}

                      <div className="agent-info">
                        <img
                          src={`/images/all_icons/${agent.icon}`}
                          alt={`${agent.name} icon`}
                          className="agent-icon-page"
                        />
                        <span className="agent-name">{agent.name}</span>
                      </div>
                      <div className="agent-categories">
                        <span className="agent-description">
                          {agent.miniDescription}
                        </span>
                        <span className="made-by-label">
                          Made by: {agent.creatorName || 'Calk Team'}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollArea>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Agents;
