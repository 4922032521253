import React, { useState, useEffect } from 'react';
import { AlertDialog, Flex, Text, Checkbox } from '@radix-ui/themes';
import { FaTimes, FaFolder, FaFileAlt } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import './DrivePopup.css';

const FileList = ({ files, selectedItems, onItemSelect, integration }) => {
  const [expandedFolders, setExpandedFolders] = useState({});
  const [fileStructure, setFileStructure] = useState({ rootFiles: [], folderContents: {} });

  useEffect(() => {
    organizeFiles(files);
  }, [files]);

  const organizeFiles = (files) => {
    const structure = {
      rootFiles: [],
      folderContents: {}
    };

    // First, identify all folders and create containers for their contents
    files.forEach(file => {
      if (file.mimeType === 'application/vnd.google-apps.folder') {
        structure.folderContents[file.id] = [];
      }
    });

    // Then, organize files into their respective folders or root
    files.forEach(file => {
      if (file.parents && file.parents.length > 0) {
        const parentId = file.parents[0];
        if (structure.folderContents[parentId]) {
          structure.folderContents[parentId].push(file);
        } else {
          structure.rootFiles.push(file);
        }
      } else {
        structure.rootFiles.push(file);
      }
    });

    setFileStructure(structure);
  };

  const toggleFolder = (folderId) => {
    setExpandedFolders(prev => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  const handleFolderSelect = (folder) => {
    onItemSelect(folder);
    
    // If folder is being selected, also select all its contents
    const contents = fileStructure.folderContents[folder.id] || [];
    if (!selectedItems.some(item => item.id === folder.id)) {
      contents.forEach(file => {
        if (!selectedItems.some(item => item.id === file.id)) {
          onItemSelect(file);
        }
      });
    }
  };

  const renderFile = (file) => {
    const isFolder = file.mimeType === 'application/vnd.google-apps.folder';
    const isExpanded = expandedFolders[file.id];
    const folderContents = isFolder ? fileStructure.folderContents[file.id] || [] : [];
    const isSelected = selectedItems.some(item => item.id === file.id);

    return (
      <div key={file.id} className="drive-file-entry">
        <div className="drive-file-item">
          <Flex align="center" gap="2">
            <Checkbox 
              checked={isSelected}
              onCheckedChange={() => isFolder ? handleFolderSelect(file) : onItemSelect(file)}
            />
            {isFolder && (
              <button 
                className="drive-folder-button"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFolder(file.id);
                }}
              >
                {isExpanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
              </button>
            )}
            {isFolder ? (
              <FaFolder className="drive-folder-icon" />
            ) : (
              <FaFileAlt className="drive-file-icon" />
            )}
            <span className="drive-file-name">{file.name}</span>
          </Flex>
          
          {isFolder && (
            <span className="drive-folder-count">
              {folderContents.length} items
            </span>
          )}
        </div>
        
        {isFolder && isExpanded && (
          <div className="drive-folder-contents">
            {folderContents.map(childFile => renderFile(childFile))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="drive-file-list">
      {fileStructure.rootFiles.map(file => renderFile(file))}
    </div>
  );
};

const DrivePopup = ({ 
  isOpen, 
  onClose, 
  files, 
  selectedItems,
  onItemSelect, 
  onSave,
  integration,
  isLoading
}) => {
  return (
    <AlertDialog.Root open={isOpen}>
      <AlertDialog.Content className="drive-popup-modal">
        <div className="drive-popup-header">
          <Flex align="center" justify="between">
            <Flex align="center" gap="2">
              <img 
                src={`/images/google-drive.png`} 
                alt={integration} 
                className="integration-icon" 
              />
              <Text className="drive-popup-title">
                Save Google Drive References
              </Text>
            </Flex>
            <button className="drive-popup-close" onClick={onClose}>
              <FaTimes />
            </button>
          </Flex>
        </div>

        <div className="drive-popup-content">
          {isLoading ? (
            <div className="drive-analyzing-container">
              <video autoPlay loop muted playsInline className="drive-loader-video">
                <source src="/loader.mp4" type="video/mp4" />
              </video>
            </div>
          ) : (
            <FileList 
              files={files}
              selectedItems={selectedItems}
              onItemSelect={onItemSelect}
              integration={integration}
            />
          )}
        </div>

        <div className="drive-popup-footer">
          <span className="drive-selected-count">
            Selected: {selectedItems.length} items
          </span>
          <button 
            className="drive-continue-button"
            disabled={selectedItems.length === 0}
            onClick={onSave}
          >
            Save References
          </button>
        </div>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};

export default DrivePopup;